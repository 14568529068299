<template>
  <div
    class="tw-flex tw-w-full tw-flex-col tw-gap-7 tw-px-7 tw-py-12"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-bottom: 1px solid var(--c-${vars.borderBottomColor}); border-top: 1px solid var(--c-${vars.borderTopColor});`"
  >
    <h3>{{ vars.titleText }}</h3>

    <p>{{ vars.descriptionText }}</p>

    <div class="tw-flex tw-max-w-fit tw-flex-col tw-gap-7">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`info-card-button-${index}`"
        :to="button.link"
        :class="`n-${button.type}`"
        >{{ button.label }}</nuxt-link
      >
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'InfoCard',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
